<script setup lang="ts">
import type { CategoryImageNavigationItem } from '~/types/models/cms/cmsElementTypes';

withDefaults(
    defineProps<{
        categories: CategoryImageNavigationItem[];
        restrictSize?: boolean;
    }>(),
    {
        restrictSize: false,
    },
);

const { pathToSeoUrl } = useLanguageSelect();
</script>

<template>
    <div
        v-if="categories.length"
        class="flex items-center justify-center gap-x-8 gap-y-4 flex-wrap"
    >
        <template
            v-for="category in categories"
            :key="category.categoryUrl"
        >
            <NuxtLink
                v-if="category.mediaUrl"
                :to="pathToSeoUrl(category.categoryUrl)"
                :title="category.categoryName"
                class="inline-flex items-center justify-center"
            >
                <SharedImage
                    :alt="category.categoryName"
                    :media-src="category.mediaUrl"
                    width="180"
                    height="180"
                    sizes="180px"
                    :class="{
                        'max-h-full': !restrictSize,
                        'max-h-[50px]': restrictSize,
                    }"
                />
            </NuxtLink>
        </template>
    </div>
</template>
